img {
  margin: 1rem 0;
}

mark {
  background-color: #fffeca;
}

.header {
  text-align: left;
  font-size: 1.8rem;
  font-family: "Crimson Text", serif;
  line-height: 1.6rem;
}

.img {
  width: 100%;
}

.img-wrapper {
  width: 100%;
  margin: 5% 0;
}

.img-2-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.img-wrapper-25 {
  width: 40%;
}

.img-wrapper-30,
.img-wrapper-40,
.img-wrapper-70,
.img-wrapper-80,
.img-wrapper-90 {
  width: 100%;
}

/* .img-wrapper-100-plus, .img-wrapper-full {
  width: 100%;
  margin-left: -10%;
  margin-right: -10%;
} */

.images {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.mobile-back {
  position: fixed;
  top: 5%;
  left: 3%;
  width: 6%;
  display: block;
}

#body-proj {
  background-color: white;
  padding: 0 3rem 10rem 3rem;
  height: 100%;
  width: 100%;
  position: fixed;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
  line-height: 1.5rem;
}

#overview-chairish {
  background-image: url("./chairish/chairish-cover.png");
  background-size: cover;
  background-position: center;
}

#overview-square {
  background-image: url("./square/header.png");
  background-position: top;
  background-size: cover;
}

/* TABLET PORTRAIT */
@media screen and (min-width: 600px) and (orientation: portrait) {
  .mobile-back {
    display: none;
  }
}

/* TABLET LANDSCAPE */
@media screen and (min-width: 600px) and (orientation: landscape) {
  .images {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .img {
    width: 100%;
  }

  .img-2-wrapper {
    flex-direction: row;
  }

  .img-wrapper {
    width: 47%;
    /*box-shadow: 0 0 4px 0 lightgrey;*/
  }

  .img-wrapper-25 {
    width: 25%;
  }

  .img-wrapper-30 {
    width: 30%;
  }

  .img-wrapper-40 {
    width: 40%;
  }

  .img-wrapper-70 {
    width: 70%;
  }

  .img-wrapper-80 {
    width: 80%;
  }

  .img-wrapper-90 {
    width: 90%;
  }

  .img-wrapper-100 {
    width: 100%;
  }

  /* .img-wrapper-100-plus {
    width: 160%;
    margin: 0 -30%;
  }

  .img-wrapper-full {
    width: 202%;
    margin: 0 -51%;
  } */

  .mobile-back {
    display: none;
  }
}

@media screen and (min-width: 1200px) and (orientation: landscape) {
  .img-wrapper-full {
    width: 100%;
    /* margin: 0 -65%; */
  }
}
