
@-webkit-keyframes static {
  0% {opacity: 0.3;}
  5% {opacity: 0.1;}
  10% {opacity: 0.05;}
  20% {opacity: 0.2;}
  30% {opacity: 0;}
  45% {opacity: 0.1;}
  50% {opacity: 0.3;}
  52% {opacity: 0;}
  55% {opacity: 0.3;}
  58% {opacity: 0.1;}
  65% {opacity: 0.05;}
  80% {opacity: 0.3;}
  84% {opacity: 0.1;}
  88% {opacity: 0.05;}
  95% {opacity: 0.1;}
  100% {opacity: 0.3;}
}

@keyframes static {
  0% {opacity: 0.3;}
  5% {opacity: 0.1;}
  10% {opacity: 0.05;}
  20% {opacity: 0.2;}
  30% {opacity: 0;}
  45% {opacity: 0.1;}
  50% {opacity: 0.3;}
  52% {opacity: 0;}
  55% {opacity: 0.3;}
  58% {opacity: 0.1;}
  65% {opacity: 0.05;}
  80% {opacity: 0.3;}
  84% {opacity: 0.1;}
  88% {opacity: 0.05;}
  95% {opacity: 0.1;}
  100% {opacity: 0.3;}
}

.back {
  position: fixed;
  top: 1%;
  left: 3%;
  font-family: 'Yeseva One', serif;
  color: black;
}

.back:hover {
  text-decoration: line-through;
}

.col {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}

.photo-img {
  width: 100%;
  margin-bottom: 5%;
}

.img-loc1 {
  z-index: 1;
  position: relative;
  color: white;
  font-family: 'Yeseva One';
  font-size: 1.5em;
  top: -19%;
  margin: auto;
}

.img-loc2 {
  z-index: 1;
  position: relative;
  color: white;
  font-family: Karla;
  text-transform: uppercase;
  letter-spacing: 1px;
  top: -19%;
  margin: auto;
}

.img-text {
  z-index: 1;
  position: relative;
}

.img-title {
  color: white;
  font-family: 'Yeseva One', serif;
  font-size: 2.5em;
  margin: 0;
}

#photos {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

#static {
  z-index: 1;
  opacity: 0.05;
  margin-top: -100%;
  -webkit-animation: static 10s 1s infinite reverse;
          animation: static 10s 1s infinite reverse;
}

/* TABLET PORTRAIT */
@media screen and (min-width: 600px) and (orientation: portrait) {

}

/* TABLET LANDSCAPE */
@media screen and (min-width: 600px) and (orientation: landscape) {

}

@media screen and (orientation: landscape) {

  @-webkit-keyframes sound {
    0% {}
    100% {}
  }

  @keyframes sound {
    0% {}
    100% {}
  }

  @-webkit-keyframes static {
    0% {opacity: 0.3;}
    5% {opacity: 0.1;}
    10% {opacity: 0.05;}
    20% {opacity: 0.2;}
    30% {opacity: 0;}
    45% {opacity: 0.1;}
    50% {opacity: 0.3;}
    52% {opacity: 0;}
    55% {opacity: 0.3;}
    58% {opacity: 0.1;}
    65% {opacity: 0.05;}
    80% {opacity: 0.3;}
    84% {opacity: 0.1;}
    88% {opacity: 0.05;}
    95% {opacity: 0.1;}
    100% {opacity: 0.3;}
  }

  @keyframes static {
    0% {opacity: 0.3;}
    5% {opacity: 0.1;}
    10% {opacity: 0.05;}
    20% {opacity: 0.2;}
    30% {opacity: 0;}
    45% {opacity: 0.1;}
    50% {opacity: 0.3;}
    52% {opacity: 0;}
    55% {opacity: 0.3;}
    58% {opacity: 0.1;}
    65% {opacity: 0.05;}
    80% {opacity: 0.3;}
    84% {opacity: 0.1;}
    88% {opacity: 0.05;}
    95% {opacity: 0.1;}
    100% {opacity: 0.3;}
  }

  .back {
    position: fixed;
    top: 3%;
    left: 3%;
    font-family: 'Yeseva One', serif;
    color: black;
  }

  .back:hover {
    text-decoration: line-through;
  }

  .col {
    width: 49%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
  }

  .photo-img {

  }

  .img-loc1 {
    z-index: 1;
    position: relative;
    color: white;
    font-family: 'Yeseva One';
    font-size: 1.5em;
    top: -19%;
    margin: auto;
  }

  .img-loc2 {
    z-index: 1;
    position: relative;
    color: white;
    font-family: Karla;
    text-transform: uppercase;
    letter-spacing: 1px;
    top: -19%;
    margin: auto;
  }

  .img-text {
    z-index: 1;
    position: relative;
  }

  .img-title {
    color: white;
    font-family: 'Yeseva One', serif;
    font-size: 2.5em;
    margin: 0;
  }

  #photos {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-pack: justify;
        justify-content: space-between;
  }

  #static {
    z-index: 1;
    opacity: 0.05;
    margin-top: -100%;
    -webkit-animation: static 10s 1s infinite reverse;
            animation: static 10s 1s infinite reverse;
  }

}
