html {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow-y: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  width: 100%;
  position: fixed;
}

#body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Inconsolata", monospace;
  font-size: 16px;
}

#body-inner {
  margin: 0;
  padding: 3rem;
  height: 100%;
  width: 100%;
  position: fixed;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-wrap: break-word;
  background-color: white;
  background-size: contain;
  -webkit-scroll-snap-type: proximity;
  -ms-scroll-snap-type: proximity;
  scroll-snap-type: proximity;
  scroll-behavior: smooth;
}

a {
  text-decoration: underline;
  color: black;
}

h1 {
  font-size: 2em;
  font-family: "Crimson Text", serif;
  margin: 2rem 0;
  font-weight: 300;
  line-height: 1.2em;
}

h2 {
  font-family: "Crimson Text", serif;
  margin: 0;
  font-size: 2em;
  line-height: 1.4em;
  font-weight: normal;
}

h4 {
  font-family: "Inconsolata", monospace;
  text-transform: uppercase;
}

hr {
  background-color: #e4e4e4;
  border: none;
  height: 1px;
  margin: 5rem 0;
}

.contact-icon {
  width: 2rem;
}

.fade {
  opacity: 0;
}

.grid-container {
  display: grid;
  gap: 2rem;
  /* grid-template-rows: repeat(12, auto); */
  margin: 2rem 0 0 0;
  min-height: 0;
  min-width: 0;
  overflow: auto;
}

.grid-item-1 {
  grid-column: 1 / 2;
}

.grid-item-1-4 {
  grid-column: 1 / 4;
}

.grid-item-1-6 {
  grid-column: 1 / 6;
}

.grid-item-1-8 {
  grid-column: 1 / 8;
}

.grid-item-2 {
  grid-column: 2 / 3;
}

.grid-item-5 {
  grid-column: 5 / 12;
}

.grid-item-7 {
  grid-column: 7 / 12;
}

.grid-item-full {
  grid-column: 1 / 12;
}

.link-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.margin-v {
  margin: 4rem 0;
}

.nav {
  /* background-color: white; */
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  z-index: 1000;
  /* margin: 2rem 3rem; */
}

.nav-contact {
  display: flex;
  justify-content: flex-end;
}

.nav-link {
  margin: 0 0.5rem 0 0;
  transition: color 0.2s ease-in-out;
}

.nav-wrapper {
  display: none;
  flex-direction: row;
  align-items: center;
  margin: 0 1rem 0 0;
}

.proj-img {
  border: 1px solid #e4e4e4;
  border-radius: 12px;
  width: 100%;
  margin: auto;
  height: 400px;
  background-position: center;
  background-size: cover;
}

#square {
  background-image: url("./square/checkout-cover.png");
  background-size: fill;
  background-repeat: no-repeat;
}

#chairish {
  background-image: url("./chairish/chairish-cover.png");
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: none;
  background: rgba(150, 150, 150, 0.5);
  z-index: 3;
}

/* TABLET PORTRAIT */
@media screen and (min-width: 600px) and (orientation: portrait) {
  /* 
  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 2.5em;
  } */

  .grid-container {
    display: grid;
    margin: 5rem 0;
  }

  .nav-wrapper {
    display: flex;
  }
}

/* TABLET LANDSCAPE */
@media screen and (min-width: 600px) and (orientation: landscape) {
  /* 
  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 2.5em;
  } */

  .contact-icon {
    width: 1.8rem;
  }

  .grid-container {
    display: grid;
    margin: 5rem 0 0 0;
  }

  .margin-v {
    margin: 1rem 0;
  }

  /* .grid-item-2 {
    grid-column: 2 / 3;
  }
  
  .grid-item-5 {
    grid-column: 5 / 12;
  } */

  .nav-wrapper {
    display: flex;
  }

  .proj-img {
    /* height: 400px; */
  }
}

/* Desktop & iPad Pro landscape */
@media screen and (min-width: 1200px) and (orientation: landscape) {
  /* 
  h1 {
    font-size: 2em;
  }
*/

  ::-webkit-scrollbar {
    width: 10px;
  }
}
